import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: {
    "liff-header": () => import("@/layout/header.vue"),
  },
  data: () => ({
    checkIso27001DefaultPasswordInterval: null,
    popupKey: "reset-default-password-warning",
    showSidebar: false,
  }),
  mounted() {
    this.showSidebar = false;
  },
  methods: {
    async readMemberProfile() {
      if (!this.isLogin) return;
      const isNoRegister = this.$route.query.no_register == 1
      try {
        const data = await this.$api.collection.memberApi.profile();
        if (isNoRegister) {
          data.hasProvider = []
          data.hasStore = []
        }
        this.$store.dispatch(`member/set`, data);
        const currMemberRole = this.currMemberRole;
        const roles = !currMemberRole ? [] : currMemberRole.roles;
        const currentRoute = this.$router.currentRoute;
        const allowedRoles = currentRoute.meta.allowedRoles;

        if (currMemberRole && !currMemberRole._isManager) {
          const checked = typeof allowedRoles != 'undefined' && roles.some((item) => allowedRoles.includes(item));
          if (!checked) {
            let route;
            if (this.tokenRole == "store") {
              route = {
                name: "store-switch-identity",
                params: {
                  providerId: this.providerId,
                  auth: "auth",
                },
              };
            } else {
              route = { name: "provider-switch-identity" };
            }
            route.name != currentRoute.name && this.$router.push(route);
          }
        }

        return data;
      } catch (error) {
        console.warn(error);
      }
    },
    setupLayout() {
      this.$store.dispatch("base/setupLayoutName", this.layoutName);
    },
    async checkIso27001DefaultPassword() {
      if (!this.iso27001Feature) return;
      if (!this.isLogin) return;
      if (!this.showChangeDefaultPasswordWarning) return;
      await this.$helper.delay(0.7);
      this.checkIso27001DefaultPasswordKernel();
      window.clearInterval(this.checkIso27001DefaultPasswordInterval);
      this.checkIso27001DefaultPasswordInterval = null;
      this.checkIso27001DefaultPasswordInterval = window.setInterval(() => {
        this.checkIso27001DefaultPasswordKernel();
      }, 10000);
    },
    async checkIso27001DefaultPasswordKernel() {
      if (this.isResetPasswordPage) return;
      if (!this.showChangeDefaultPasswordWarning) {
        window.clearInterval(this.checkIso27001DefaultPasswordInterval);
        return;
      }
      this.$apopup.base({
        key: this.popupKey,
        title: this.$t(`action.reset-default-password`),
        content: this.$t(`action.reset-default-password.help`),
        close: false,
        persistent: true,
        applyCallback: () => {
          this.$router.push({
            name: "reset-password",
            query: { path: this.$route.fullPath },
          });
        },
      });
    },
  },
  computed: {
    isResetPasswordPage() {
      return this.$route.name === "reset-password";
    },
    iso27001Feature() {
      return this.$eagleLodash.get(this.feature, "iso27001");
    },
    showChangeDefaultPasswordWarning() {
      return (
        this.$eagleLodash.get(this.tokenData, "has_change_default_password") ===
        false
      );
    },
    ...mapGetters("member", ["currMemberRole", "providerId"]),
    ...mapGetters({
      layoutReady: "base/layoutReady",
      siteLogoUrl: "base/siteLogoUrl",
      siteName: "base/siteName",
      isLogin: "token/isLogin",
      tokenData: "token/data",
      feature: "base/feature",
    }),
  },
});
